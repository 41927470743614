import React, { useState } from 'react';
import { ENetworkEvent } from 'src/network/enums/ENetworkEvent';
import sendRequest, { RequestError } from 'src/network/SendRequest';
import { getErrorMessageForCode } from "src/network/ErrorMessages";
import { EPage } from 'src/components/pages/EPage';
import PageProps from 'src/components/pages/PageProps';
import SessionState, { Player } from 'src/SessionState';
import SubmitButton from 'src/components/common/SubmitButton';
import SubmittableInput from 'src/components/common/SubmittableInput';

function RoomCodePage({ socket, setPage, setError }: PageProps) {
  const [roomCode, setRoomCode] = useState<string>();
  const buttonRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const clearError = () => {
    setError(undefined);
  };

  const isValidRoomCode = () => {
    if (roomCode) return roomCode.length === 4;

    return false;
  };

  const sendRequestRoom = async () => {
    if (isValidRoomCode()) {
      try {
        const formattedRoomCode = roomCode?.toUpperCase();
        const result: {
          has_password: boolean;
          allow_spectators: boolean;
          players: Player[];
        } = await sendRequest(
          socket,
          ENetworkEvent.RequestRoom,
          { room_code: formattedRoomCode },
          5000,
        );
        SessionState.roomCode = formattedRoomCode;
        SessionState.hasPassword = result.has_password;
        SessionState.allowSpectators = result.allow_spectators;
        SessionState.players = result.players;
        setPage(EPage.Login);
        clearError();
      } catch (e) {
        const error = e as RequestError;
        setError(getErrorMessageForCode(error.error_code));
      }
    }
  };

  return (
    <div>
      <div className="field">
        <label className="label">Enter Party Code</label>
        <p className="control has-icons-left">
          <SubmittableInput
            button={buttonRef.current}
            style={{ textTransform: 'uppercase' }}
            type="text"
            maxLength={4}
            placeholder="Party Code"
            autoFocus={true}
            onChange={(event) => setRoomCode(event.target.value)}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-gamepad"></i>
          </span>
        </p>
      </div>
      <div>
        <SubmitButton
          ref={buttonRef}
          text={'Submit'}
          enabled={isValidRoomCode()}
          alignRight={false}
          onClick={sendRequestRoom}
        />
      </div>
    </div>
  );
}

export default RoomCodePage;
