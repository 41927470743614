import React, { useState } from 'react';
import PageProps from 'src/components/pages/PageProps';
import SubmitButton from 'src/components/common/SubmitButton';
import { EPage } from './EPage';
import { ENetworkEvent } from '../../network/enums/ENetworkEvent';
import sendRequest, { RequestError } from '../../network/SendRequest';
import { getErrorMessageForCode } from "../../network/ErrorMessages";
import SessionState from '../../SessionState';

function ActivityPage({ socket, setPage, setError }: PageProps) {
  const [vote, setVote] = useState<number>();
  const buttonRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const clearError = () => {
    setError(undefined);
  };

  const isValidVote = () => {
    return !!vote && vote > 0;
  };
  
  const sendVote = async () => {
    if (isValidVote()) {
      try {
        await sendRequest(
          socket,
          ENetworkEvent.SendActivity,
          { room_code: SessionState.roomCode, vote: vote ? vote - 1 : 0 },
          5000,
        );
        setPage(EPage.Waiting);
        clearError();
      } catch (e) {
        const error = e as RequestError;
        setError(getErrorMessageForCode(error.error_code));
      }
    }
  };

  const renderOptions = () => {
    return (
      <div className="field">
        <label className="label">{SessionState.activityText}</label>
        <div className="control">
          {SessionState.activityOptions?.map((value, index) => (
            <p key={index}>
              <label className="radio">
                <input
                  type="radio"
                  name="players"
                  onChange={() => {
                    setVote(index + 1);
                  }}
                />
                &nbsp;{value}
              </label>
            </p>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="field">{renderOptions()}</div>
      <div>
        <SubmitButton
          ref={buttonRef}
          text={'Submit'}
          enabled={isValidVote()}
          alignRight={false}
          onClick={sendVote}
        />
      </div>
    </div>
  );
}

export default ActivityPage;
