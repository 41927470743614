import React, { useState } from "react";
import PageProps from "src/components/pages/PageProps";
import SubmitButton from "src/components/common/SubmitButton";
import { EPage } from "./EPage";
import { ENetworkEvent } from "../../network/enums/ENetworkEvent";
import sendRequest, { RequestError } from "../../network/SendRequest";
import { getErrorMessageForCode } from "../../network/ErrorMessages";
import SessionState from "../../SessionState";
import { EReactionAwards } from "../../enums/EReaction";

function ReactionPage({ socket, setPage, setError }: PageProps) {
  const [answer, setAnswer] = useState<number>();
  const [reaction, setReaction] = useState<number>();
  const buttonRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const clearError = () => {
    setError(undefined);
  };

  const isValidReaction = () => {
    return !!answer && answer > 0 && !!reaction && reaction > 0;
  };

  const sendReaction = async () => {
    if (isValidReaction()) {
      try {
        const response: {
          isEnding: boolean;
        }  = await sendRequest(
          socket,
          ENetworkEvent.SendReaction,
          { room_code: SessionState.roomCode, answer, reaction },
          5000
        );
        
        if (response.isEnding) {
          setPage(EPage.ThankYou);
        } else {
          setPage(EPage.Waiting); 
        }
        clearError();
      } catch (e) {
        const error = e as RequestError;
        setError(getErrorMessageForCode(error.error_code));
      }
    }
  };

  const getReactionText = (reaction: EReactionAwards) => {
    switch (reaction) {
      case EReactionAwards.GoldenJoker: {
        return "LOL!";
      }
      case EReactionAwards.Punbelievable: {
        return "Good wordplay!";
      }
      case EReactionAwards.MeanieMedal: {
        return "SAVAGE BURN!";
      }
      case EReactionAwards.DirtyBirdTrophy: {
        return "Eww!";
      }
      case EReactionAwards.Offsensive: {
        return "Offensive!";
      }
    }
  };

  const renderOptions = () => {
    return (
      <div className="field">
        <label className="label">Choose an Answer</label>
        <div className="control">
          {SessionState.answers?.map((answer, index) => (
            <p key={index}>
              <label className="radio">
                <input
                  type="radio"
                  name="answers"
                  onChange={() => {
                    setAnswer(answer.id);
                  }}
                />
                &nbsp;{answer.value}
              </label>
            </p>
          ))}
        </div>
        <br />
        <label className="label">Choose a Reaction</label>
        <div className="control">
          {Object.keys(EReactionAwards)
            .filter((value) => !isNaN(Number(value)))
            .map((value, index) => (
              <p key={index}>
                <label className="radio">
                  <input
                    type="radio"
                    name="reactions"
                    onChange={() => {
                      setReaction(index + 1);
                    }}
                  />
                  &nbsp;{getReactionText(Number(value))}
                </label>
              </p>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="field">{renderOptions()}</div>
      <div>
        <SubmitButton
          ref={buttonRef}
          text={"Submit"}
          enabled={isValidReaction()}
          alignRight={false}
          onClick={sendReaction}
        />
      </div>
    </div>
  );
}

export default ReactionPage;
