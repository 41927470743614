import { EErrorCode } from "src/network/enums/EErrorCode";
import { ENetworkEvent } from "src/network/enums/ENetworkEvent";

export const errorMessagesForEvent: { [key in ENetworkEvent]?: string } = {
  [ENetworkEvent.Disconnect]: 'Connection was lost.',
  [ENetworkEvent.RoomClosed]: 'The room was closed.',
};

export const errorMessagesForCode: { [key in EErrorCode]: string } = {
  [EErrorCode.Unknown]: 'An unknown error has occurred',
  [EErrorCode.InvalidRoom]: 'The room does not exist.',
  [EErrorCode.MaximumPlayersReached]: 'Maximum players reached.',
  [EErrorCode.MaximumSpectatorsReached]: 'Maximum spectators reached.',
  [EErrorCode.InvalidPassword]: 'The password was incorrect.',
  [EErrorCode.InvalidPlayer]: 'The player could not be found.',
  [EErrorCode.PlayerCannotAnswer]: 'The answer could not be submitted.',
  [EErrorCode.PlayerCannotVote]: 'The vote could not be submitted.',
  [EErrorCode.PlayerCannotGizmo]: 'The gizmo could not be submitted.',
  [EErrorCode.SpectatorAlreadyResponded]: 'The spectator response could not be submitted.',
  [EErrorCode.PlayerDuplicateName]: 'A player with that name has already joined the room.',
  [EErrorCode.PlayerIsActive]: 'Cannot rejoin as that player, as they are already active.',
  [EErrorCode.Timeout]: 'The request timed out.',
};

export function getErrorMessageForCode(code: EErrorCode): string {
  if (code === EErrorCode.Unknown) {
    return `An unknown error has occurred. (Code: ${code})`;
  } else if (!Object.prototype.hasOwnProperty.call(errorMessagesForCode, code)) {
    return `An unknown error has occurred. (Code: -1)`;
  } else {
    return errorMessagesForCode[code];
  }
}
