import React, { useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import RoomCodePage from './components/pages/RoomCodePage';
import ErrorNotification from 'src/components/common/ErrorNotification';
import { EPage } from 'src/components/pages/EPage';
import LoginPage from 'src/components/pages/LoginPage';
import SessionState, { Answer } from 'src/SessionState';
import WaitingPage from 'src/components/pages/WaitingPage';
import { ENetworkEvent } from 'src/network/enums/ENetworkEvent';
import { errorMessagesForEvent } from 'src/network/ErrorMessages';
import AnswerPage from './components/pages/AnswerPage';
import VotePage from './components/pages/VotePage';
import SpectatorsWelcomePage from './components/pages/SpectatorsWelcomePage';
import { EActivity } from './enums/EActivity';
import ActivityPage from './components/pages/ActivityPage';
import { EPlayerType } from './enums/EPlayerType';
import ReactionPage from './components/pages/ReactionPage';
import ThankYouPage from './components/pages/ThankYouPage';
import WaitingPostAnswerPage from './components/pages/WaitingPostAnswerPage';
import GizmoPage from './components/pages/GizmoPage';

function App() {
  const [socket, setSocket] = useState<Socket>();
  const [error, setError] = useState<string | undefined>();
  const [page, setPage] = useState<EPage>(EPage.Enter);

  useEffect(() => {
    const url = process.env.REACT_APP_SERVER_URL || '';
    const newSocket = io(url);
    setSocket(newSocket);
    listenEvents(newSocket);
    return () => {
      if (socket) {
        forgetEvents(socket);
        socket.close();
      }
    };
  }, [setSocket]);

  const listenEvents = (socket: Socket) => {
    if (socket) {
      socket.on(ENetworkEvent.Connect, () => {
        setError(undefined);
      });
      socket.on(ENetworkEvent.Disconnect, () => {
        SessionState.clear();
        setPage(EPage.Enter);
        setError(errorMessagesForEvent[ENetworkEvent.Disconnect]);
      });
      socket.on(ENetworkEvent.RoomClosed, () => {
        SessionState.clear();
        setPage(EPage.Enter);
        setError(errorMessagesForEvent[ENetworkEvent.RoomClosed]);
      });
      socket.on(
        ENetworkEvent.RequestAnswer,
        (
          answer_start_requirements: string[],
          current_activity: EActivity,
          activity_text: string,
          activity_options: string[],
        ) => {
          if (SessionState.currentPage === EPage.Login) {
            return;
          }
          
          SessionState.answerStartWithRequirements = answer_start_requirements; 
          // TODO: Remove the need for this initial value of empty string
          if (SessionState.answerStartWithRequirements.length === 0) {
            SessionState.answerStartWithRequirements.push("");
          }
          
          if (SessionState.isPlayer()) {
            setPage(EPage.Answer);
          } else {
            const isSame =
              SessionState.currentActivity === current_activity &&
              SessionState.activityText === activity_text;
            if (!isSame) {
              SessionState.currentActivity = current_activity;
              SessionState.activityText = activity_text;
              SessionState.activityOptions = activity_options;
              setPage(EPage.Activity);
            }
          }
        },
      );
      socket.on(
        ENetworkEvent.RequestVote,
        (current_round: number, current_phase: number, answers: Answer[]) => {
          if (SessionState.currentPage === EPage.Login) {
            return;
          }
          
          SessionState.answers = answers;
          
          const isPlayer: boolean = SessionState.isPlayer();
          if (!isPlayer) {
            if (current_phase == 1) {
              setPage(EPage.Reaction);
            } else if (current_phase == 0) {
              if (current_round % 2 == 1) {
                setPage(EPage.Reaction);
              } else {
                setPage(EPage.Vote); 
              }
            }
          } else {
            setPage(EPage.Vote);
          }
        },
      );
      socket.on(ENetworkEvent.RequestWaiting, (type: EPlayerType) => {
        const isPlayer = SessionState.isPlayer();
        if (type == EPlayerType.Player && isPlayer) {
          setPage(EPage.Waiting);
        } else if (type == EPlayerType.Spectator && !isPlayer && SessionState.currentPage != EPage.ThankYou) {
          setPage(EPage.Waiting);
        }
      });
    }
  };

  const forgetEvents = (socket: Socket) => {
    if (socket) {
      socket.off(ENetworkEvent.Connect);
      socket.off(ENetworkEvent.Disconnect);
      socket.off(ENetworkEvent.RoomClosed);
      socket.off(ENetworkEvent.RequestAnswer);
      socket.off(ENetworkEvent.RequestVote);
      socket.off(ENetworkEvent.RequestWaiting);
    }
  };

  const getHeader = (title: string) => {
    return (
      <div className="columns">
        <div className="column">
          <h1 className="title">{title}</h1>
        </div>
        <div className="column">
          <h2 className="subtitle has-text-right">
            {SessionState.roomCode?.toUpperCase()}
          </h2>
        </div>
      </div>
    );
  };

  const getActivityTitle = () => {
    switch (SessionState.currentActivity) {
      case EActivity.PredictTheWinner: {
        return 'Predict The Winner!';
      }
      case EActivity.Superlatives: {
        return 'Superlatives';
      }
      case EActivity.UseYourWordsTrivia: {
        return 'Use Your Words Trivia';
      }
      case EActivity.ClassicGameShowTrivia: {
        return 'Classic Game Show Trivia';
      }
      case EActivity.TheSpectatorsGiveth: {
        return 'The Spectators Giveth';
      }
      case EActivity.TheSpectatorsTaketh: {
        return 'The Spectators Taketh Away';
      }
      case EActivity.SpectatorPoll: {
        return 'Spectator Poll';
      }
    }
    return '';
  };

  const getPage = () => {
    SessionState.currentPage = page;
    switch (page) {
      case EPage.Enter:
        return (
          <div>
            {getHeader('Join Room')}
            <RoomCodePage
              socket={socket}
              setPage={setPage}
              setError={setError}
            />
          </div>
        );
      case EPage.Login:
        return (
          <div>
            {getHeader('Join Room')}
            <LoginPage socket={socket} setPage={setPage} setError={setError} />
          </div>
        );
      case EPage.Waiting:
        return (
          <div>
            {getHeader('Waiting')}
            <WaitingPage />
          </div>
        );
        
      case EPage.Answer:
        return (
          <div>
            {getHeader('Submit Answer')}
            <AnswerPage socket={socket} setPage={setPage} setError={setError} />
          </div>
        );
      case EPage.Vote:
        return (
          <div>
            {getHeader('Vote For Your Favorite')}
            <VotePage socket={socket} setPage={setPage} setError={setError} />
          </div>
        );
      case EPage.SpectatorsWelcome:
        return (
          <div>
            {getHeader('Welcome Spectator!')}
            <SpectatorsWelcomePage />
          </div>
        );
      case EPage.Activity:
        return (
          <div>
            {getHeader(getActivityTitle())}
            <ActivityPage
              socket={socket}
              setPage={setPage}
              setError={setError}
            />
          </div>
        );
      case EPage.Reaction:
        return (
          <div>
            {getHeader('Spectator Awards')}
            <ReactionPage
              socket={socket}
              setPage={setPage}
              setError={setError}
            />
          </div>
        );
      case EPage.ThankYou:
        return (
          <div>
            {getHeader('Thanks For Playing!')}
            <ThankYouPage />
          </div>
        );
      case EPage.WaitingPostAnswer:
        return (
          <div>
            {getHeader('Waiting')}
            <WaitingPostAnswerPage socket={socket} setPage={setPage} setError={setError} />
          </div>
        );
      case EPage.Gizmo:
        return (
          <div>
            {getHeader('Use a Gizmo')}
            <GizmoPage socket={socket} setPage={setPage} setError={setError} />
          </div>
        );
    }
  };

  return (
    <div>
      <section className="hero is-link">
        <div className="hero-body has-text-centered">
          <p className="title">Use Your Words 2</p>
        </div>
      </section>
      <br />
      <div className="container box">
        <ErrorNotification error={error} setError={setError} />
        {getPage()}
      </div>
    </div>
  );
}

export default App;
