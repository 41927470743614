export enum EErrorCode {
  Timeout = 10000,
  Unknown = 50000,
  InvalidRoom,
  MaximumPlayersReached,
  MaximumSpectatorsReached,
  InvalidPassword,
  InvalidPlayer,
  PlayerCannotAnswer,
  PlayerCannotVote,
  PlayerCannotGizmo,
  SpectatorAlreadyResponded,
  PlayerDuplicateName,
  PlayerIsActive
}
