import React, { useState } from 'react';
import PageProps from 'src/components/pages/PageProps';
import SubmitButton from 'src/components/common/SubmitButton';
import { EPage } from './EPage';
import { ENetworkEvent } from '../../network/enums/ENetworkEvent';
import sendRequest, { RequestError } from '../../network/SendRequest';
import { getErrorMessageForCode } from "../../network/ErrorMessages";
import SessionState from '../../SessionState';

function VotePage({ socket, setPage, setError }: PageProps) {
  const [vote, setVote] = useState<number>();
  const buttonRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const clearError = () => {
    setError(undefined);
  };

  const isValidVote = () => {
    return !!vote && vote > 0;
  };

  const sendVote = async () => {
    if (isValidVote()) {
      try {
        await sendRequest(
          socket,
          ENetworkEvent.SendVote,
          { room_code: SessionState.roomCode, vote },
          5000,
        );
        setPage(EPage.Waiting);
        clearError();
      } catch (e) {
        const error = e as RequestError;
        setError(getErrorMessageForCode(error.error_code));
      }
    }
  };

  const renderAnswers = () => {
    return (
      <div className="field">
        <label className="label">Answers</label>
        <div className="control">
          {SessionState.answers?.map((value) => (
            <p key={value.id}>
              <label className="radio">
                <input
                  type="radio"
                  name="players"
                  disabled={value.player_id == SessionState.playerId}
                  onChange={() => {
                    setVote(value.id);
                  }}
                />
                &nbsp;{value.value}
              </label>
            </p>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="field">{renderAnswers()}</div>
      <div>
        <SubmitButton
          ref={buttonRef}
          text={'Submit'}
          enabled={isValidVote()}
          alignRight={false}
          onClick={sendVote}
        />
      </div>
    </div>
  );
}

export default VotePage;
